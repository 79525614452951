body{
  background: #003366;
  color: #fff;
}

#myHeader{
  background: #002255;
  box-shadow: 0px 0px 6px #000;
  color: #fff;
  padding-top: 40px;
}

#myHeader a{
  color: #fff;
}

.myNavItem{
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
}

.btn-link{
  color: #fff;
}

.ffield{
  background: url(./field.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 65vw;
}

.ffield .pos{
  height: 40px;
  padding-top: 10px; 
  text-align: center;
}

.featureList li{
  border-bottom: 1px solid #fff;
  padding: 10px;
}
